<template>
  <section>
    <b-row>
      <b-col md="2"></b-col>
      <b-col md="8">
        <h3>Elenco Punti Vendita</h3>
        <b-card>
          <b-table :fields="fields" :items="items">
            <template #cell(id)="data">{{ data.item.id_user }}</template>
            <template #cell(nome_azienda)="data">{{
                companies[data.item.user_companyname]
            }}</template>
            <template #cell(nome)="data">{{ data.item.user_name }}</template>
            <template #cell(cognome)="data">{{
                data.item.user_surname
            }}</template>

          </b-table>
        </b-card>
      </b-col>
      <b-col md="2"></b-col>
    </b-row>
  </section>
</template>

<script>
import { Requests } from "@/api/requests.js";

import { BCard, BCol, BRow, BTable } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BTable,
  },

  data: () => ({
    fields: ["id", "nome_azienda", "nome", "cognome",],
    items: [],
    companies: {
      "deco": "Decò",
      "dodeca": "Dodecà"
    }
  }),

  created() {
    this.getSalesPointList();
  },

  methods: {
    async getSalesPointList() {
      try {
        const response = await Requests.getSalesPointList();
        this.items = response;
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
